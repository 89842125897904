<template>
  <div class="mb-1">
    <p v-if="task_details.date" class="mb-0">
      <span><strong>Дата: </strong> {{ task_details.date }}</span>
    </p>
    <p v-if="task_details.is_official_employment || task_details.credit_terms_year ||
       task_details.initial_fee_percent" class="mb-0">
      <span>
        {{ task_details.is_official_employment ? 'Официально трудоустроен, ' : '' }}
        {{ task_details.credit_terms_year ? 'срок ' + task_details.credit_terms_year + ' г.,' : '' }}
        аванс {{ task_details.initial_fee_percent }}%
      </span>
    </p>
    <p v-if="task_details.mark || task_details.model" class="mt-1 mb-0">
      <span>
        <strong>
          {{
            task_details.mark ?
              (typeof task_details.mark === 'object' ? task_details.mark.name : JSON.parse(task_details.mark).name) :
              ''
          }}
          {{
            task_details.model ?
              (typeof task_details.model === 'object' ? task_details.model.name : JSON.parse(task_details.model).name) :
              ''
          }}
        </strong>
      </span>
    </p>
    <p v-if="task_details.year || task_details.race || task_details.link || task_details.city || task_details.region"
       class="mb-0">
      <span>
        {{
          task_details.city ?
            'город ' + (typeof task_details.city === 'object' ? task_details.city.title : JSON.parse(task_details.city).title) + '; ' :
            ''
        }}
        {{
          task_details.region ?
            (typeof task_details.region === 'object' ? task_details.region.title : JSON.parse(task_details.region).title) + ' область; ' :
            ''
        }}
        {{ task_details.year ? task_details.year + ' год; ' : '' }}
        {{ task_details.race ? 'пробег ' + task_details.race + ' тыс.км.' : '' }}
        <a v-if="task_details.link" :href="task_details.link" target="_blank">
          Ссылка на авто
        </a>
      </span>
    </p>
    <p v-if="task_details.year_from || task_details.year_to || task_details.race_from ||
       task_details.race_to || task_details.color || task_details.gearbox || task_details.price_from ||
       task_details.price_to" class="mb-0">
      <span>
        {{ task_details.year_from ? 'от ' + task_details.year_from + ' г., ' : '' }}
        {{ task_details.year_to ? 'до ' + task_details.year_to + ' г.; ' : '' }}
        {{ task_details.race_from ? 'от ' + task_details.race_from + ' тыс. км., ' : '' }}
        {{ task_details.race_to ? 'до ' + task_details.race_to + ' тыс. км.; ' : '' }}
        {{ task_details.color ? 'цвет ' + task_details.color.title.toLowerCase() + '; ' : '' }}
        {{
          task_details.gearbox ?
            'КПП ' + (typeof task_details.gearbox === 'object' ? task_details.gearbox.title : JSON.parse(task_details.gearbox).title) + '; ' :
            ''
        }}
        {{
          task_details.transmission ?
            'привод ' + (typeof task_details.transmission === 'object' ? task_details.transmission.title : JSON.parse(task_details.transmission).title) + '; ' :
            ''
        }}
        {{ task_details.engine ? 'двигатель ' + task_details.engine + ' л.; ' : '' }}
        {{ task_details.price_from ? 'от ' + task_details.price_from + '$, ' : '' }}
        {{ task_details.price_to ? 'до ' + task_details.price_to + '$; ' : '' }}
      </span>
    </p>
    <p v-if="task_details.bargain_sum" class="mb-0">
      <span>
        <strong>Сумма торга: </strong> {{ task_details.bargain_sum }}$
        (<strong class="font-medium text-muted">{{ task_car_price - task_details.bargain_sum }}$</strong>)
      </span>
    </p>
    <p v-if="task_details.source_item" class="mb-0">
      <span>
        Источник: <strong>{{ task_details.source_item }}</strong>
      </span>
    </p>

    <hr v-if="with_delimiter" class="mt-1 mb-1"/>
  </div>
</template>

<script>
export default {
  name: 'task-item-details-block',

  props: {
    task_details: {
      type: Object,
    },
    task_car_price: {
      type: Number,
    },
    with_delimiter: {
      type: Boolean|null,
      default: true,
    }
  },
}
</script>

<style scoped>

</style>
